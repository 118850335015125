<template>
    <div class="bg-color">
        <Navbar />
        <Header :category="'press'" :bannerTitle="bannerTitle" :breadCrumb="breadCrumb" />
        <AlbumPhotoBody />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import AlbumPhotoBody from '../Organizers/AlbumPhoto/AlbumPhotoBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'AlbumPhotoBodyMain',
        components: {
            Navbar,
            Header,
            AlbumPhotoBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: "ACTIVITIES",
                breadCrumb: [{
                    name: this.$t('navbar.menu_3'),
                    path: "/press-album"
                },
                {
                    name: this.$t('navbar.menu_3_2')
                }],
            }
        },
        methods: {

        }
    }
</script>

<style>
    .bg-color {
        background-color: #eff4f5;
    }
</style>